.login-container{
    border-radius: 8px;
    box-shadow: 0 30px 60px #0000004d;
}

.login-form {
    padding: 20px;
    text-align: center;
}

.logo {
    width: 100%;
    margin-bottom: 20px;
    @media (min-width: 480px) {
        width: 350px;
    }
}

.form-group {
    margin-bottom: 15px;
}

h3 {
    color: #3f000f;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="email"],
input[type="password"] {
    width: calc(100% - 22px); /* Ancho ajustado */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Para que el padding y el borde se incluyan en el ancho */
}

.auth-action_button {
    background-color: #3f000f;
    color: #fff;
    padding: 15px 80px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-transform: uppercase;
}

#formFooter {
    background-color: #f6f6f6;
    border-top: 1px solid #dce8f1;
    padding: 25px;
    text-align: center;
    -webkit-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
}
#formFooter a {
    color: #3f000f;
    display: inline-block;
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
}