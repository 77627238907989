@media print {

  @page {
    margin: 5mm; /* Ajusta los márgenes de la página */
  }

  body {
    margin: 0;
    padding: 20px;
    -webkit-print-color-adjust: exact;
    page-break-inside: avoid; /* Evita saltos de página dentro de un elemento */
  }
}