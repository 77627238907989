html, body {
    margin: 0;
    padding: 0;
    background-color: #FFF;
    color: #000000;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1rem;
    display: block;
}

header {
    background: linear-gradient(0deg, rgba(72,68,68,.9058823529), rgba(72,68,68,.9058823529)), url('https://unal.edu.co/fileadmin/_processed_/3/7/csm_UNAL_abri-08-24-02_fed164965d.jpg');
}

footer{
    position: absolute;
    bottom: 0;
}

.menuPrincipal {
    float: right;
    margin-right: 3rem;
}